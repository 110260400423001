import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'

export type Status = 'succeeded' | 'failed' | 'canceled' | 'polling' | 'waiting'

export interface Prediction {
  id: string
  date: string
  status: Status
}

const QUEUE_KEY = 'QK'
const queue = ref<Prediction[]>([])
const currentPollId = ref()
const currentTimeoutId = ref()

async function loadQueue() {
  if (!Capacitor.isNativePlatform()) {
    console.warn('NOT A PHONE')
    return []
  }
  const { value } = await Preferences.get({ key: QUEUE_KEY })
  const q = value ? JSON.parse(value) : []
  queue.value = q.filter(
    (p: Prediction) => !['succeeded', 'canceled'].includes(p.status),
  )
}
function changeStatus(id: string, status: Status) {
  queue.value = queue.value.map((prediction) =>
    prediction.id === id ? { ...prediction, status } : prediction,
  )
}
function getPrediction(id: string) {
  const prediction = queue.value.find((prediction) => prediction.id === id)
  return prediction ? prediction : null
}
watch(queue, async (q) => {
  console.info('PRED Q (not the bond one)', JSON.stringify(q))
  await Preferences.set({ key: QUEUE_KEY, value: JSON.stringify(q) })
})

loadQueue()
export default function () {
  const route = useRoute()
  const { $appFetch } = useNuxtApp()

  async function fetchGenerated(id: string) {
    const blob = await $appFetch('/generated/' + id).catch((_) => null)
    if (!blob) {
      return null
    }
    return blobtoBase64(blob)
  }

  async function pollPrediction(id: string, date: string) {
    currentPollId.value = setInterval(fetchPrediction, 1000, id, date)
    currentTimeoutId.value = setTimeout(async () => {
      clearInterval(currentPollId.value)
      subscribe(id, date)
    }, 20000)
  }
  async function fetchPrediction(id: string, date: string) {
    const { status, output } = await $appFetch('/api/ai/get', {
      query: { id },
    })
    if (status.endsWith('ed')) {
      clearInterval(currentPollId.value)
      clearTimeout(currentTimeoutId.value)
      if (status === 'succeeded' && output)
        await savePrediction(output[0], date, id)
      else changeStatus(id, status)
    }
  }
  async function savePrediction(url: string, date: string, id: string) {
    const { saveImage } = await useMedias()
    await saveImage(url, date, id).catch(() => {
      changeStatus(id, 'failed')
      throw createError(
        `Couldn't save Image for id ${id} and url:${url} , Sorry mate`,
      )
    })
    changeStatus(id, 'succeeded')
    if (route.name === 'theme-date-camera') return // attempt to remove some doublon
    if (route.name === 'theme-date' && route.params?.date === date) {
      // le type est toujours sur le thème du jour
      await navigateTo(`/theme/${date}?show=${id}`, { replace: true })
    } else {
      const toast = useToast()
      toast.add({
        title: `Youpi l'image du ${date} est prête`,
        timeout: 9000,
        actions: [
          {
            label: 'Go see it!',
            click: async () =>
              await navigateTo(`/theme/${date}?show=${id}`, {
                replace: true,
              }),
          },
        ],
      })
    }
  }
  async function processPending() {
    for (const prediction of queue.value) {
      if (prediction.status === 'waiting' || prediction.status === 'polling') {
        const base64 = await fetchGenerated(prediction.id)
        if (base64) {
          await savePrediction(base64, prediction.date, prediction.id)
        } else if (prediction.status === 'polling') {
          subscribe(prediction.id, prediction.date)
        }
      }
    }
  }
  async function subscribe(id: string, date: string) {
    const { getToken } = await usePush()
    await $appFetch('/api/ai/subscribe', {
      params: { token: getToken(), id, date },
    })
    changeStatus(id, 'waiting')
  }

  return {
    create: async (date: string, image: string): Promise<void> => {
      const { id } = await $appFetch('/api/ai/create', {
        method: 'POST',
        body: {
          date,
          image,
        },
      })
      queue.value.push({
        id,
        date,
        status: 'polling',
      })
      pollPrediction(id, date)
      await navigateTo({
        path: '/theme/' + route.params.date,
        query: {
          id: id,
        },
      })
    },
    async updatePrediction(id: string, date: string) {
      const prediction = getPrediction(id) || {
        id,
        date,
        status: 'waiting',
      } // if prediction not yet on phone
      if (prediction.status === 'succeeded') return
      const base64 = await fetchGenerated(id)
      if (!base64) throw createError(`Generated Image for ${id} not found`)
      await savePrediction(base64, prediction.date, prediction.id)
    },
    getStatusRef(id: string) {
      return computed(() => getPrediction(id)?.status)
    },
    processPending,
    subscribe,
  }
}
