import { default as calendrierxzbinl0to0Meta } from "/opt/buildhome/repo/pages/app/calendrier.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as cameraGxFio15UzsMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/camera.vue?macro=true";
import { default as indexrEg444kN8wMeta } from "/opt/buildhome/repo/pages/app/theme/[date]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: calendrierxzbinl0to0Meta?.name ?? "app-calendrier",
    path: calendrierxzbinl0to0Meta?.path ?? "/app/calendrier",
    meta: calendrierxzbinl0to0Meta || {},
    alias: calendrierxzbinl0to0Meta?.alias || [],
    redirect: calendrierxzbinl0to0Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/calendrier.vue").then(m => m.default || m)
  },
  {
    name: indexIbNDbq129sMeta?.name ?? "app",
    path: indexIbNDbq129sMeta?.path ?? "/app",
    meta: indexIbNDbq129sMeta || {},
    alias: indexIbNDbq129sMeta?.alias || [],
    redirect: indexIbNDbq129sMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: cameraGxFio15UzsMeta?.name ?? "app-theme-date-camera",
    path: cameraGxFio15UzsMeta?.path ?? "/app/theme/:date()/camera",
    meta: cameraGxFio15UzsMeta || {},
    alias: cameraGxFio15UzsMeta?.alias || [],
    redirect: cameraGxFio15UzsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/camera.vue").then(m => m.default || m)
  },
  {
    name: indexrEg444kN8wMeta?.name ?? "app-theme-date",
    path: indexrEg444kN8wMeta?.path ?? "/app/theme/:date()",
    meta: indexrEg444kN8wMeta || {},
    alias: indexrEg444kN8wMeta?.alias || [],
    redirect: indexrEg444kN8wMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/app/theme/[date]/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]