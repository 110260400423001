<template>
  <NuxtLayout>
    <NuxtPage />
    <UNotifications />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { StatusBar } from '@capacitor/status-bar'
import { Capacitor } from '@capacitor/core'

useHead({
  titleTemplate: 'Almana.cc',
})

const { registerNotifications, addListeners } = await usePush()
await registerNotifications()
addListeners()

const hideStatusBar = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.hide()
      if (Capacitor.getPlatform() === 'android')
        await StatusBar.setOverlaysWebView({ overlay: true })
    } catch (error) {
      console.error('Erreur lors de la gestion de la barre de statut:', error)
    }
  }
}
onMounted(() => {
  usePrediction().processPending()
  hideStatusBar()
})
</script>
